*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	position: relative;
	margin: 0;
	padding: 0;
	font-family: var(--font-main);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	line-height: var(--line-height);
	color: var(--text);
	overflow-x: hidden;
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);

	@include media-breakpoint-down(sm) {
		padding-block-start: 80px;
	}
}

h1 {
	margin: 0 0 2rem 0;
	padding: 0;
	@include fluid-type($min_width, $max_width, 32px, 50px);
	font-weight: 500;
	line-height: 1.2;
	color: var(--title);
}

h2 {
	margin: 0 0 1.5rem 0;
	padding: 0;
	@include fluid-type($min_width, $max_width, 28px, 40px);
	font-weight: 500;
	line-height: 1.2;
	color: var(--title);

	&.title-handwritten {
		font-size: 4em;
	}
}

h3 {
	margin: 0 0 1rem 0;
	padding: 0;
	@include fluid-type($min_width, $max_width, 24px, 32px);
	font-weight: 500;
	line-height: 1.3;
	color: var(--title);

	// .title-handwritten {
	// 	@include fluid-type($min_width, $max_width, 40px, 60px);
	// 	position: relative;
	// 	top: .1em;
	// 	margin-inline-start: .1em;
	// }
}

h4 {
	margin: 0 0 1rem 0;
	padding: 0;
	@include fluid-type($min_width, $max_width, 20px, 24px);
	line-height: 1.4;
	color: var(--title);
}

h5 {
	margin: 0 0 1rem 0;
	padding: 0;
	font-size: var(--font-size);
	line-height: 1.6;
	color: var(--title);
}

p {
	margin: 0 0 1rem 0;
	color: var(--text);
	@include fluid-type($min_width, $max_width, 16px, 20px);

	&:last-child {
		margin: 0;
	}

	&.zitat {
		font-size: 1.7rem;
		font-style: italic;
		color: var(--color-tertiary);
	}

	&.bildunterschrift {
		font-size: 0.9rem;
		margin-top: 0.5rem;
	}
}

[data-font="hero"] {
	font-family: var(--font-title);
	color: var(--color-secondary);
	font-style: italic;

	span {
		font-family: var(--font-main);
		font-style: initial;
		color: white;
	}
}

.title-handwritten {
	position: relative;
	top: .12em;
	font-family: var(--font-handwritten);
	font-size: 2em;
	line-height: .9;
}

.title-info {
	font-weight: 600;
	color: var(--text);

	&+h2 {
		margin-top: 0;
	}

	// &.title-handwritten {
	// 	@include fluid-type($min_width, $max_width, 32px, 40px);
	// }
}

.title-serif {
	font-family: var(--font-title);
	font-style: italic;
}

.title-h1 {
	@include fluid-type($min_width, $max_width, 32px, 50px);
}

.title-primary {
	--title: var(--color-primary);
}

.title-secondary {
	--title: var(--color-secondary);
}

.primary {
	color: var(--color-primary);
}

.secondary {
	color: var(--color-secondary);
}

b,
strong,
.bold {
	font-weight: 600;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		margin: 0;
		padding: 0;
	}
}

.mt-1 {
	margin-block-start: 1rem !important;
}

.mt-2 {
	margin-block-start: 2rem !important;
}

.mt-3 {
	margin-block-start: 3rem !important;
}

.mt-4 {
	margin-block-start: 4rem !important;
}

.mt-5 {
	margin-block-start: 5rem !important;
}

.mb-1 {
	margin-block-end: 1rem !important;
}

.mb-2 {
	margin-block-end: 2rem !important;
}

.mb-3 {
	margin-block-end: 3rem !important;
}

.mb-4 {
	margin-block-end: 4rem !important;
}

.mb-5 {
	margin-block-end: 5rem !important;
}