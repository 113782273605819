.stepnavigation {
	$this: &;

	&-left,
	&-right {
		position: fixed;
		display: flex;
		align-items: center;
		width: 120px;
		height: 160px;
		transform: translateY(-50%);
		z-index: 400;

        @include media-breakpoint-down(md) {
            display: none;
        }
		
		@media (hover: none) {
			display: none;
		}
	}

	&__btn {
		position: relative;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 80px;
		height: 80px;
		background-color: var(--color-primary-1);
		border-radius: 50vw;
		@include effect(all, 0.5s, ease-in-out);

		&::after {
			content: "";
			position: relative;
			width: 20px;
			height: 20px;
			background-color: white;
			mask-image: url("../img/icon/icon-arrow-right.svg");
			mask-repeat: no-repeat;
			mask-position: center;
			mask-size: 20px;
			opacity: 0;
			@include effect(all, 0.5s, ease-in-out);
		}
	}

	&__title {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		display: inline-flex;
        justify-content: center;
        align-items: center;
        max-width: 300px;
		height: 80px;        
		padding-inline: 5rem;
		font-size: 14px;
		font-weight: 500;
		color: white;
		background-color: var(--color-primary-1);
		border-radius: 50vw;
        animation: titleOut .15s forwards;
        opacity: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
	}

	&-left {
		justify-content: flex-end;
		inset: 50% auto auto 0;

		#{$this}__btn {
			transform: translateX(-80px) scale(0.75);

			&::after {
				mask-image: url("../img/icon/icon-arrow-left.svg");
			}
		}

		#{$this}__title {
            padding-inline: 5rem 2rem;
			left: 0;
		}

		&:hover {
            #{$this}__btn {
                max-width: auto;
				transform: translateX(0) scale(1);

				&::after {
					opacity: 1;
				}
			}

            #{$this}__title {
                animation: titleIn .3s forwards .5s;
			}
		}
	}

	&-right {
		justify-content: flex-start;
		inset: 50% 0 auto auto;

		#{$this}__btn {
			transform: translateX(80px) scale(0.75);

			&::after {
				mask-image: url("../img/icon/icon-arrow-right.svg");
			}
		}

		#{$this}__title {
            padding-inline: 2rem 5rem;
			right: 0;
		}

		&:hover {
            #{$this}__btn {
                max-width: auto;
				transform: translateX(0) scale(1);

				&::after {
					opacity: 1;
				}
			}

            #{$this}__title {
                animation: titleIn 1s forwards .5s;
			}
		}
	}

	&--deactive {
		display: none;
	}


    @keyframes titleIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes titleOut {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
}
