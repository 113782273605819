.counter-grid {
    $this: &;
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 20px;

    @include media-breakpoint-down(xl) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &__item {
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 2rem;
        width: 100%;
        aspect-ratio: 1/1;
        background-color: var(--bg-light);
        text-align: center;
        height: 100%;

        @include media-breakpoint-down(sm) {
            aspect-ratio: inherit;
        }
    
        &[data-bg="border"] {
            background-color: white;
            border: 1px solid var(--border-light);
        }
    
        &[data-bg="white"] {
            background-color: white;
        }
    
        &[data-bg="primary"] {
            background-color: var(--bg-primary);
        }
    
        &[data-bg="secondary"] {
            background-color: var(--bg-secondary);
        }

        &[data-bg="dark"] {
            background-color: var(--bg-dark);
            color: white;
        }
    
        &[data-bg="light"] {
            background-color: var(--bg-light);
        }

        &[data-bg="primary"],
        &[data-bg="dark"] {
            #{$this}__number {
                --title: white;
            }

            #{$this}__text {
                --text: white;
            }
        }
    }

    &__icon {
        flex: 0 0 auto;
        height: 80px;
        width: auto;
        margin-block-end: 1rem;

        &-sm {
           padding: 15px 0;
        }
    }

    &__number {
        //font-size: 40px;
        margin-block-end: .5rem;
        margin: 0 0 1rem 0;
        padding: 0;
        @include fluid-type($min_width, $max_width, 32px, 40px);
        font-weight: 500;
        line-height: 1.3;
        color: var(--title);
    }

    &__text {
        font-size: 16px;
    }
    
}

