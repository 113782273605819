.mitarbeiter-grid {
    $this: &;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 40px;

    @include media-breakpoint-down(xl) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(md) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.mitarbeiter {
    &--item {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;

        figure {
            max-width: 200px;
        }
    }

    &--content {
        text-align: center;

        span {
            font-size: 16px;
	        font-weight: 600;
        }

        h3 {
            @include fluid-type($min_width, $max_width, 20px, 24px);
            font-weight: 600;
        }
    }
}